<template>
  <WidgetWrapper
    :title="$$t('Audience Location')"
    class="bg-transparent border border-hover p-5 ph-panel--rounded-small"
  >
    <LMap
      id="tournament-dashboard-map"
      :center="mapCenter"
      :zoom="mapZoom"
      :options="mapOptions"
      class="bg-transparent"
    >
      <ChoroplethLayer
        :data="myCountries"
        title-key="name"
        id-key="id"
        :value="value"
        geojson-id-key="iso_a2"
        :geojson="mapGeoJson"
        :color-scale="colorScale"
        :stroke-width="1"
        :current-stroke-width="2"
        :current-stroke-color="currentStrokeColor"
      >
        <template slot-scope="props">
          <InfoControl
            :item="props.currentItem"
            :unit="props.unit"
            class="current-item-info"
            hide-if-empty
            :title="$$t('Info by country')"
            :placeholder="$$t('Select highlighted country to see details')"
          />
          <ReferenceChart
            :title="$$t('Number of applicants')"
            :color-scale="colorScale"
            :min="props.min"
            :max="props.max"
            position="bottomright"
            class="legend-info"
          />
        </template>
      </ChoroplethLayer>
      <LControlZoom position="bottomright" />
    </LMap>
  </WidgetWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { translate } from '@/mixins/translate';
import WidgetWrapper from '@/views/tournaments/management/dashboard/components/WidgetWrapper';
import { LMap, LControlZoom } from 'vue2-leaflet';
import {
  // InfoControl,
  ReferenceChart,
  ChoroplethLayer,
} from 'vue-choropleth-fork';
import InfoControl from '@/views/tournaments/management/dashboard/components/customMapInfoComponent';
const WORLD_MAP_DATA = require('@/assets/json/custom.geo.json');
const MAP_CENTER = [40.33985508235374, 11.038000903809557];
const INITIAL_ZOOM = 1;

export default {
  name: 'LocationWidget',
  components: {
    WidgetWrapper,
    LMap,
    LControlZoom,
    InfoControl,
    ReferenceChart,
    ChoroplethLayer,
  },
  mixins: [translate('_web_tournament_dashboard')],
  data() {
    return {
      myCountries: [],
      colorScale: [
        this.$scss.colors.yellowBg.slice(1),
        this.$scss.colors.orangeBg.slice(1),
        this.$scss.colors.blueBg.slice(1),
      ],
      strokeColor: this.$scss.colors.secondaryTextBg.slice(1),
      currentStrokeColor: this.$scss.colors.accentBg.slice(1),
      value: {
        key: 'count',
        metric: this.$t('_web_participants', 'participants'),
      },
    };
  },
  computed: {
    ...mapGetters(['countries']),
    mapGeoJson() {
      return WORLD_MAP_DATA;
    },
    mapZoom() {
      return INITIAL_ZOOM;
    },
    mapCenter() {
      return MAP_CENTER;
    },
    mapOptions() {
      return {
        attributionControl: false,
        zoomControl: false,
      };
    },
  },
  created() {
    this.fetchTournamentPlayersStatistics(this.$route.params.tournamentID)
      .then(response => {
        this.myCountries = Object.keys(response.locationStatistic).map(key => ({
          id: key,
          name: this.$lodash.get(
            this.countries.find(el => el.code === key),
            'name',
            key
          ),
          count: response.locationStatistic[key],
        }));
      })
      .catch(this.errorNotify);
  },
  methods: {
    ...mapActions(['fetchTournamentPlayersStatistics', 'errorNotify']),
  },
};
</script>
<style lang="scss">
@import '~leaflet/dist/leaflet.css';
#tournament-dashboard-map {
  height: 400px;
  .leaflet-control-container {
    .leaflet-control {
      h4 {
        display: none;
      }
    }
    .leaflet-bottom {
      &.leaflet-left {
        .info {
          width: 155px;
          height: 40px;
          text-align: center;
          color: $ph-card;
        }
      }
      &.leaflet-right {
        display: flex;
        align-items: flex-end;
        flex-flow: row-reverse;
        .leaflet-control-zoom {
          background: rgba(255, 255, 255, 0.8);
          box-shadow: $ph-box-shadow;
          border-radius: $ph-medium-radius;
          .leaflet-control-zoom-in,
          .leaflet-control-zoom-out {
            background: transparent;
          }
        }
        .info {
          font: inherit;
          height: 64px;
          text-align: center;
          color: $ph-card;
          padding: $ph-mini-space;
          line-height: 1rem;
          font-size: 0.75rem;
          font-weight: normal;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: $ph-box-shadow;
          border-radius: $ph-medium-radius;
          > span {
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
