<template>
  <div class="map-info-wrapper"></div>
</template>
<script>
import { prop } from '@/utils/factories';
export default {
  props: {
    item: prop(Object),
    unit: prop(String),
    placeholder: prop(String),
    hideIfEmpty: prop(Boolean),
    title: prop(String),
    position: prop(String, 'bottomleft'),
  },
  watch: {
    item(newValue) {
      this.mapObject.update({
        ...newValue,
        unit: this.unit,
        title: this.title,
        placeholder: this.placeholder,
        hideIfEmpty: this.hideIfEmpty,
      });
    },
  },
  beforeDestroy() {
    if (this.parent) {
      this.parent.removeControl(this.mapObject);
    }
  },
  mounted() {
    const self = this;
    const { hideIfEmpty, unit, title, placeholder, position } = self;

    // eslint-disable-next-line no-undef
    self.mapObject = L.control({
      position: position,
    });
    // eslint-disable-next-line no-unused-vars
    self.mapObject.onAdd = function(map) {
      // eslint-disable-next-line no-undef
      this._div = L.DomUtil.create(
        'div',
        `${self.$vnode.data.staticClass} info`
      ); // create a div with a class "info"
      this.update({
        name: '',
        value: 0,
        unit,
        placeholder,
        title,
        hideIfEmpty,
      });
      return this._div;
    };
    self.mapObject.update = function({
      name,
      value,
      extraValues = undefined,
      unit,
      title,
      placeholder,
      hideIfEmpty,
    }) {
      if (name.length) {
        this._div.innerHTML = `<h4> ${title} </h4>
                    <b> ${name} </b><br /> ${value} ${unit}`;
        if (extraValues) {
          for (let x of extraValues) {
            this._div.innerHTML =
              this._div.innerHTML + `<br /> ${x.value} ${x.metric}`;
          }
        }
      } else if (placeholder) {
        this._div.innerHTML = `<b> ${placeholder} </b>`;
      }
      if ((hideIfEmpty && !name.length) || (!name.length && !placeholder)) {
        this._div.classList.add('is-empty');
      } else {
        this._div.classList.remove('is-empty');
      }
    };

    if (self.$parent._isMounted) {
      self.deferredMountedTo(self.$parent.mapObject);
    }
  },
  methods: {
    deferredMountedTo(parent) {
      this.parent = parent;
      if (this.mapObject) {
        this.mapObject.addTo(parent);
      }
    },
  },
};
</script>
<style lang="scss">
.info {
  padding: $ph-mini-space;
  line-height: 1rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: $ph-box-shadow;
  border-radius: $ph-medium-radius;
  &.is-empty {
    background: transparent !important;
    color: transparent !important;
    box-shadow: none !important;
  }
}
</style>
